html, body {
  margin: 0;
  height: 100%;
  font-family: 'Poppins', sans-serif;
  background-color: var(--main-bg-color);
  color: var(--main-text-color);
  overflow-y: hidden;
  overflow-x: hidden;
  scrollbar-width: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  background: var(--primary-button-bg-color);
  color: var(--primary-button-text-color);
  border: none;
  border-radius: 2px;
  font-size: 1rem;
  padding: 4px 8px;
  cursor: pointer;
}

form {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
}

input {
  margin-bottom: 1rem;
  background: none;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid var(--main-color);
  font-size: 1rem;
  color: var(--main-text-color);
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

input:not([type="radio"]):not([type="checkbox"]) {
  -webkit-appearance: none;
  border-radius: 0;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  margin: 0;
  background-color: var(--main-bg-color);
  font: inherit;
  color: var(--main-text-color);
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid var(--main-color);
  border-radius: 0.15em;
  border-radius: 2px;
  transform: translateY(0.175em);
  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--main-color-selected);
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

input[type="checkbox"]:disabled {
  opacity: var(--disabled-alpha);
  cursor: not-allowed;
}

select {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid var(--main-color);
  font-size: 1rem;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='%236EB533' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 50%;
  color: var(--main-text-color);
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-right: 1.5rem;
}

select:focus {
  outline: none !important;
}

a {
  color: var(--main-color);
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

tr:nth-child(even) {
  background-color: var(--table-alternate-color);
}

td, th {
  border: 1px solid var(--table-border-color);
  text-align: left;
  padding: 8px;
}

@media print
{
  table { page-break-after:auto }
  tr    { page-break-inside:avoid; page-break-after:auto }
  td    { page-break-inside:avoid; page-break-after:auto }
  thead { display:table-header-group }
  tfoot { display:table-footer-group }
}

.material-icons-container {
  display: table;
}

.material-icons-container > .material-icons {
  display: table-cell !important;
  vertical-align: middle !important;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

/* Rules for sizing the icon. */
.material-icons.md-16 { font-size: 16px; }
.material-icons.md-18 { font-size: 18px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }

/* Rules for using icons as black on a light background. */
.material-icons.md-dark { color: rgba(0, 0, 0, 0.54); }
.material-icons.md-dark.md-inactive { color: rgba(0, 0, 0, 0.26); }

/* Rules for using icons as white on a dark background. */
.material-icons.md-light { color: rgba(255, 255, 255, 1); }
.material-icons.md-light.md-inactive { color: rgba(255, 255, 255, 0.3); }

/**/

.header {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  height: 4rem;
  max-height: 4rem;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--toolbar-background-color);
  justify-content: space-between;
  color: var(--toolbar-text-color);
  box-shadow: 0px 1px 4px #888888;
  z-index: 100;
}

.header > .logo {
  object-fit: contain;
  min-width: 11rem;
  max-width: 11rem;
  height: 3rem;
  max-height: 3rem;
  margin: 0.5rem;
  background-color: transparent;
  background-image: url('./assets/images/logo_greensystems.png');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 0 center;
  border: none;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
}

.header > .logo:active {
  background-color: transparent;
}

.header .spacing {
  flex: 1 0 auto;
  max-height: 4rem;
}

.header button {
  width: 100%;
  height: 100%;
  max-height: 4rem;
  flex: 0 0 4rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: var(--toolbar-icon-color);
}

.header button.disabled,
.header button[disabled] {
  opacity: var(--disabled-alpha);
  cursor: not-allowed;
  pointer-events: none;
}

.header button:active {
  background-color: var(--toolbar-icon-selected-color);
}

.header .clear {
  mask: url(./assets/icons/ic_back.svg) no-repeat center !important;
  mask-size: 0% 0% !important;
  cursor: default !important;
  max-height: 4rem;
}

.header .ic-back {
  mask: url(./assets/icons/ic_back.svg) no-repeat center;
  mask-size: 45% auto;
  cursor: pointer;
  max-height: 4rem;
}

.header .ic-done {
  mask: url(./assets/icons/ic_done.svg) no-repeat center;
  mask-size: 60% auto;
  cursor: pointer;
  max-height: 4rem;
}

.header .ic-cart {
  position: relative;
  z-index: 0;
  background-color: transparent;
  cursor: pointer;
  margin-left: 8rem;
  max-height: 4rem;
}

.header .ic-cart:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: var(--main-color);
  mask: url(./assets/icons/ic_cart.svg) no-repeat center;
  mask-size: 45% auto;
}

.header .ic-cart span {
  mask: none;
  background-color: var(--main-color);
  color: var(--main-text-contrast-color);
  font-size: 0.8rem;
  position: absolute;
  bottom: 7.5%;
  padding: 0 0.4rem;
  border-radius: 0.8rem;
}

.header .ic-download {
  mask: url(./assets/icons/ic_download.svg) no-repeat center;
  mask-size: 45% auto;
  cursor: pointer;
  margin-left: 8rem;
  max-height: 4rem;
}

.header .ic-share {
  mask: url(./assets/icons/ic_share.svg) no-repeat center;
  mask-size: 45% auto;
  cursor: pointer;
  margin-left: 8rem;
  max-height: 4rem;
}

.header > .title-container {
  flex: 1 0 auto;
  display: flex;
  min-width: 20rem;
  max-height: 4rem;
  height: 4rem;
  justify-content: center;
  align-items: center;
}

.header > .title-container > p {
  max-width: 90%;
  align-self: center;
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

@media screen and (min-width: 450px) and (max-width: 700px) {
  .header > .logo {
    min-width: 3rem;
    max-width: 3rem;
    background-image: url('./assets/images/logo_greensystems_compact.png');
    margin: 0.5rem;
  }

  .header .ic-cart {
    margin-left: 0;
  }

  .header .spacing {
    max-width: 0;
  }
}

@media screen and (min-width: 0px) and (max-width: 449px) {
  .header {
    height: 7rem;
    max-height: 7rem;
  }

  .header > .logo {
    min-width: 8rem;
    max-width: 8rem;
    background-image: url('./assets/images/logo_greensystems.png');
    margin: 0.5rem;
  }

  .header .ic-cart {
    margin-left: 0;
  }

  .header .spacing {
    max-width: 0;
  }

  .header .title-container {
    width: 100%;
    height: 3rem;
    order: 5;
  }

  .header .title-container > * {
    height: 3rem;
    line-height: 3rem;
  }

  .content-under-header, .content-between-header-footer {
    top: 7rem !important;
  }
}

@media screen and (min-width: 0px) and (max-width: 449px) {
  .header > .title-container > p {
    font-size: 1.4rem;
  }
}

.content-under-header {
  position: absolute;
  display: block;
  height: auto;
  width: 100%;
  bottom: 0;
  top: 4rem;
  left: 0;
  right: 0;
  overflow-y: scroll;
}

.content-over-footer {
  position: absolute;
  display: block;
  height: auto;
  width: 100%;
  top: 0;
  bottom: 4rem;
  left: 0;
  right: 0;
  overflow-y: scroll;
}

.content-between-header-footer {
  position: absolute;
  display: block;
  height: auto;
  width: 100%;
  top: 4rem;
  bottom: 4rem;
  left: 0;
  right: 0;
  overflow-y: scroll;
}

.footer {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  height: 4rem;
  max-height: 4rem;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--toolbar-background-color);
  justify-content: space-between;
  color: var(--toolbar-text-color);
  box-shadow: 0px -1px 4px #888888;
  z-index: 100;
  padding-left: 1rem;
  padding-right: 1rem;
  align-items: center;
}

.footer.tiny {
  height: 4rem !important;
  max-height: 4rem !important;
}

.footer.tiny + .content-over-footer, .footer.tiny + .content-between-header-footer {
  bottom: 4rem !important;
}

.footer > *:nth-child(odd) {
  flex: 1 0 5rem;
}

.footer > *:nth-child(even) {
  flex: 1 0 60%;
}

.footer #contact-info {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  width: 60%;
}

.footer #contact-info > *.compact {
  display: none;
  text-align: center;
  vertical-align: middle;
}

@media screen and (min-width: 0px) and (max-width: 920px) {
  .footer {
    height: 7rem;
    max-height: 7rem;
  }

  .footer:first-child {
    order: 1;
  }

  .footer:last-child {
    order: 2;
  }

  .footer #contact-info {
    min-width: 90%;
    order: 3;
  }

  .content-over-footer, .content-between-header-footer {
    bottom: 7rem !important;
  }
}

@media screen and (min-width: 0px) and (max-width: 620px) {
  .footer #contact-info > *.compact {
    display: table;
  }

  .footer #contact-info > *.compact > :first-child {
    display: table-cell;
    vertical-align: middle;
  }

  .footer #contact-info > *.not-compact {
    display: none;
  }
}

.footer #contact-info * {
  margin-top: 0;
  margin-bottom: 0;
}

.footer #country-selection {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  justify-content: right;
}

.footer #country-selection :first-child {
  margin-right: 0.5rem;
  border-radius: 100%;
  border: 0.5px solid black;
}

/**/

#container-login {
  padding: 4rem 2rem;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
}

#container-login .logo {
  width: 22rem;
  margin-bottom: 2rem;
}

#container-login form {
  margin-bottom: 1rem;
}

#container-login a {
  cursor: pointer;
}

.catalog {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.catalog-item {
  flex: 0 0 100%;
  margin: 8px;
  box-shadow: 0 4px 8px 0 var(--shadow-color);
  transition: 0.3s;
  border-radius: 5px;
  cursor: pointer;
  background-color: var(--card-bg-color);
}

@media screen and (min-width: 0px) and (max-width: 480px) {
  .catalog-item {
    flex: 0 0 90%;
  }
}

@media screen and (min-width: 480px) and (max-width: 768px) {
  .catalog-item {
    flex: 0 0 90%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .catalog-item {
    flex: 0 0 30%;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .catalog-item {
    flex: 0 0 23%;
  }
}

@media screen and (min-width: 1200px) {
  .catalog-item {
    flex: 0 0 18%;
  }
}

.catalog-item:hover {
  box-shadow: 0 8px 16px 0 var(--shadow-color);
}

.catalog-item.inactive:hover {
  box-shadow: 0 4px 8px 0 var(--shadow-color);
  cursor: default;
}

.catalog-item > img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 5px 5px 0 0;
}

.catalog-item-content {
  padding: 2px 16px 8px 16px;
}

.catalog-item-content .title {
  font-weight: bold;
  margin-bottom: 2px;
}

.catalog-item-content > p {
  margin: 0;
}

.catalog-item-content > div > img {
  width: 1.2rem;
  height: 1.2rem;
  margin: 0px 8px 0 4px;
  display: inline;
  vertical-align: middle;
}

.catalog-item-content > div > p {
  margin: 0;
  display: inline;
}

.catalog-item-content > div > p.eco-a {
  padding: 0 0.3rem;
  background-color: var(--eco-a);
  border-radius: 2px;
}

.catalog-item-content > div > p.eco-a {
  padding: 0 0.3rem;
  background-color: var(--eco-a);
  border-radius: 2px;
}

.catalog-item-content > div > p.eco-b {
  padding: 0 0.3rem;
  background-color: var(--eco-b);
  border-radius: 2px;
}

.catalog-item-content > div > p.eco-c {
  padding: 0 0.3rem;
  background-color: var(--eco-c);
  border-radius: 2px;
}

.catalog-item-content > div > p.eco-d {
  padding: 0 0.3rem;
  background-color: var(--eco-d);
  border-radius: 2px;
}

.catalog-item-content > div > p.eco-e {
  padding: 0 0.3rem;
  background-color: var(--eco-e);
  border-radius: 2px;
}

.catalog-item-content > div > p.eco-f {
  padding: 0 0.3rem;
  background-color: var(--eco-f);
  border-radius: 2px;
}

.catalog-item-content > div > p.eco-g {
  padding: 0 0.3rem;
  background-color: var(--eco-g);
  border-radius: 2px;
}

.catalog-item-content > div.cart-stock {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
}

.cart div.cart-stock {
  display: flex;
  align-items: center;
  justify-content: left;
}

.catalog-item-content > div button.ic-remove, .catalog-item-content > div button.ic-remove.disabled,
.cart div button.ic-remove, .cart div button.ic-remove.disabled {
  width: 1.5rem;
  height: 1.5rem;
  mask: url(./assets/icons/ic_remove.svg) no-repeat center;
  mask-size: 90% auto;
  cursor: pointer;
  margin-right: 0.5rem;
  background-color: var(--main-color);
  border: none;
}

.catalog-item-content > div button.ic-remove.disabled, .cart div button.ic-remove.disabled {
  opacity: var(--disabled-alpha);
  cursor: default;
}

.catalog-item-content > div button.ic-add, .catalog-item-content > div button.ic-add.disabled,
.cart div button.ic-add, .cart div button.ic-add.disabled {
  width: 1.5rem;
  height: 1.5rem;
  mask: url(./assets/icons/ic_add.svg) no-repeat center;
  mask-size: 90% auto;
  cursor: pointer;
  margin-left: 0.5rem;
  background-color: var(--main-color);
  border: none;
}

.catalog-item-content > div button.ic-add.disabled, .cart div button.ic-add.disabled {
  opacity: var(--disabled-alpha);
  cursor: default;
}

.cart div button.ic-delete, .cart div button.ic-delete.disabled {
  width: 1.5rem;
  height: 1.5rem;
  mask: url(./assets/icons/ic_delete.svg) no-repeat center;
  mask-size: 90% auto;
  cursor: pointer;
  margin-right: 0.5rem;
  background-color: var(--main-color);
  border: none;
}

.catalog-item-content > div button.ic-delete.disabled, .cart div button.ic-delete.disabled {
  opacity: var(--disabled-alpha);
  cursor: default;
}

p.disclaimer {
  padding: 1rem;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.empty-content {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.empty-content > h1 {
  margin-bottom: 0;
}

.empty-content #animation {
  width: 20rem;
  height: 20rem;
}

#animationLoading {
  margin-left: auto;
  margin-right: auto;
  width: 200px;
  height: 200px;
}

table.cart {
  justify-content: center;
  width: 90%;
  margin: 2rem auto;
}

@media screen and (min-width: 0px) and (max-width: 480px) {
  .table.cart {
    width: 100%;
  }
}

@media screen and (min-width: 480px) and (max-width: 768px) {
  .table.cart {
    width: 100%;
  }
}

table.cart tr > td:last-child, table.cart tr > th:last-child, table.cart tr.total > td:last-child {
  background-color: var(--main-bg-color);
  border-top: none;
  border-bottom: none;
  border-right: none;
}

table.cart tr.total > td {
  border-top: 2px solid var(--table-border-color);
}

table.cart tr.total :first-child {
  font-weight: bold;
}

.componentWrapper {
  display: flex;
  margin-top: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid var(--main-color);
  border-radius: 2px;
  padding: 15px 10px 10px;
  width: 95%;
}

.componentWrapper .componentHeader {
  position: absolute;
  margin-top: -30px;
  margin-left: 10px;
  color: var(--main-text-contrast-color);
  background: var(--main-color);
  border-radius: 2px;
  padding: 2px 10px;
}

.filters {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: left;
  padding: 0.5rem 3rem;
}

.filters > * {
  width: auto;
  flex: 1 1 30%;
}

@media screen and (min-width: 0px) and (max-width: 480px) {
  .filters > * {
    width: auto;
    flex: 1 1 90%;
  }
}

@media screen and (min-width: 480px) and (max-width: 768px) {
  .filters > * {
    width: auto;
    flex: 1 1 40%;
  }
}

.filters > *.spacing {
  width: 1rem;
  flex: 0 0 1rem;
}

.filters > div > form {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

@media screen and (min-width: 0px) and (max-width: 480px) {
  .filters > div > form {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
  }
}

.filters > div > form > label {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.4em;
}

.product-container {
  margin-left: auto;
  margin-right: auto;
  width: 60%;
  padding-top: 1rem;
}

@media screen and (min-width: 0px) and (max-width: 480px) {
  .product-container {
    width: 100%;
    padding-top: 0;
  }
}

@media screen and (min-width: 480px) and (max-width: 768px) {
  .product-container {
    width: 80%;
  }
}

.header .dropdown {
  min-height: 100%;
  min-width: 4rem;
  position: absolute;
  top: 0;
  right: 0;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--toolbar-background-color);
  min-width: 160px;
  box-shadow: 0 4px 8px 0 var(--shadow-color);
  z-index: 1;
}

.dropdown-content a {
  color: var(--main-text-color) !important;
  padding: 12px 16px;
  text-decoration: none;
  display: block !important;
  cursor: pointer;
}

.dropdown-content a:hover {
  background-color: var(--hover-color);
}

.dropdown:hover .dropdown-content {
  display: block;
}

.main-color {
  color: var(--main-color);
}

.message-error {
  width: auto;
  max-width: 100%;
  margin-left: 1rem;
  margin-right: 1rem;
  text-align: center;
  background-color: var(--bg-error);
  color: var(--text-color-error);
  padding: 1rem;
  border-radius: 2px;
}

.message-success {
  max-width: 100%;
  margin-left: 1rem;
  margin-right: 1rem;
  text-align: center;
  background-color: var(--bg-success);
  color: var(--text-color-success);
  padding: 1rem;
  border-radius: 2px;
}

/*
Media Queries
=============
  Types
  -----
  all — for all media types
  print — for printers
  screen — for computer screens, tablets and, smart-phones
  speech — for screen readers that “read” the page out loud

  Breakpoints
  -----------
  320px — 480px: Mobile devices
  481px — 768px: iPads, Tablets
  769px — 1024px: Small screens, laptops
  1025px — 1200px: Desktops, large screens
  1201px and more —  Extra large screens, TV
*/