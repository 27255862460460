:root {

    /* Colors */
    --main-color: #6EB533;
    --main-color-selected: #BED014;
    --main-bg-color: #fefefe;
    --main-text-contrast-color: #ffffff;
    --main-text-color: #111111;
    --main-text-disabled-color: #11111199;
    --accent-color: #009eff;
    --hover-color: #eeeeee;
    --toolbar-background-color: #ffffff;
    --toolbar-text-color: #6EB533;
    --toolbar-icon-color: #6EB533;
    --toolbar-icon-selected-color: #6EB53396;
    --primary-button-bg-color: #6EB533;
    --primary-button-bg-selected-color: #BED014;
    --primary-button-text-color: #ffffff;
    --secondary-button-bg-color: #00000000;
    --secondary-button-bg-selected-color: #6EB53322;
    --secondary-button-text-color: #111111;
    --secondary-button-border-color: #6EB533;
    --table-alternate-color: #6eb53322;
    --table-border-color: #6EB533;
    --modal-overlay-color: #000000aa;
    --qr-bg-color: #fefefe;
    --qr-fg-color: #111111;
    --correct-color: #6cc92c;
    --correct-content-color: #ffffff;
    --warning-color: #d6a400;
    --warning-content-color: #ffffff;
    --error-color: #d80000;
    --error-content-color: #ffffff;
    --cart-header-bg-color: #6EB53322;
    --cart-header-text-color: #111111;
    --cart-divider-color: #6EB533;
    --card-bg-color: #ffffff;
    --card-placeholder-color: #cccccc;
    --eco-a: #4a9f55;
    --eco-b: #97bb53;
    --eco-c: #c3cf54;
    --eco-d: #faeb52;
    --eco-e: #efbc44;
    --eco-f: #db793c;
    --eco-g: #d03a39;
    --bg-error: #c00b0b;
    --text-color-error: #ffffff;
    --bg-success: #06a02a;
    --text-color-success: #ffffff;
  
    /* Style */
    --disabled-alpha: 0.6;
    --shadow-color: #00000033;
  }